











































































































































import { Component, Vue } from 'vue-property-decorator'
import LsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import {
    apiDistributionGradeLists,
    apiDistributionGradeDel
} from '@/api/distribution/distribution'
@Component({
    components: {
        LsPagination,
        LsDialog
    }
})
export default class DistributionGoods extends Vue {
    /** S Data **/

    pager = new RequestPaging()

    /** E Data **/

    /** S Method **/

    // 获取分销列表
    getDistributionData(): void {
        this.pager.request({
            callback: apiDistributionGradeLists,
            params: {}
        })
    }

    Del(id: Number) {
        apiDistributionGradeDel({ id }).then(() => {
            this.getDistributionData()
        })
    }

    /** E Method **/

    created() {
        this.getDistributionData()
        // this.getGoodsOtherList();
    }
}
