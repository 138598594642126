var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ls-goods"},[_c('div',{staticClass:"ls-goods__top ls-card"},[_c('el-alert',{attrs:{"title":"温馨提示：1.管理分销商的等级，系统默认等级不能删除；2.删除分销等级时，会重新调整分销商等级为系统默认等级，请谨慎操作。","type":"info","show-icon":"","closable":false}})],1),_c('div',{staticClass:"m-t-24 ls-card"},[_c('div',{staticClass:"m-b-24"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$router.push(
                        '/distribution/distribution_grade_edit?mode=add&flag=0'
                    )}}},[_vm._v("新增分销等级 ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.pager.loading),expression:"pager.loading"}],ref:"paneTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.pager.lists,"size":"mini"}},[_c('el-table-column',{attrs:{"prop":"weights_desc","label":"等级级别","width":"180"}}),_c('el-table-column',{attrs:{"prop":"name","label":"等级名称","width":"180"}}),_c('el-table-column',{attrs:{"prop":"first_ratio","label":"自购佣金比例","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.self_ratio)+"%")])]}}])}),_c('el-table-column',{attrs:{"prop":"first_ratio","label":"一级佣金比例","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.first_ratio)+"%")])]}}])}),_c('el-table-column',{attrs:{"prop":"second_ratio","label":"二级佣金比例","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.second_ratio)+"%")])]}}])}),_c('el-table-column',{attrs:{"prop":"member_num","label":"分销商数","width":"180"}}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","min-width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"m-r-10",attrs:{"slot":"trigger","type":"text","size":"mini"},on:{"click":function($event){return _vm.$router.push({
                                path: '/distribution/distribution_grade_edit',
                                query: {
                                    id: scope.row.id,
                                    mode: 'edit',
                                    disabled: true,
                                    flag: scope.row.is_default
                                }
                            })}},slot:"trigger"},[_vm._v("详情")]),_c('el-button',{staticClass:"m-r-10",attrs:{"slot":"trigger","type":"text","size":"mini"},on:{"click":function($event){return _vm.$router.push({
                                path: '/distribution/distribution_grade_edit',
                                query: {
                                    id: scope.row.id,
                                    mode: 'edit',
                                    flag: scope.row.is_default
                                }
                            })}},slot:"trigger"},[_vm._v("编辑")]),(!scope.row.is_default)?_c('ls-dialog',{staticClass:"inline m-l-10",attrs:{"title":"删除等级","content":("确定将此：" + (scope.row.name) + "删除？")},on:{"confirm":function($event){return _vm.Del([scope.row.id])}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"mini"},slot:"trigger"},[_vm._v("删除")])],1):_vm._e()]}}])})],1),_c('div',{staticClass:"m-t-24 flex row-right"},[_c('ls-pagination',{on:{"change":function($event){return _vm.getDistributionData()}},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }